import React, { useState } from 'react';
import emailJs from 'emailjs-com';
import Snackbar from '../snackbar/snackbar';

const BexconForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        info: '',
        message: ''
    });

    const [snackbar, setSnackbar] = useState({
        isOpen: false,
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const userId = '19CqRANYBTe_Vs7Fp';
        const serviceId = 'service_52ggdjb';
        const templateId = 'template_zd4k9pp';

        emailJs.send(serviceId, templateId, formData, userId)
            .then((response) => {
                setSnackbar({
                    isOpen: true,
                    message: 'Message envoyé avec succès!'
                });
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    info: '',
                    message: ''
                });
                setTimeout(closeSnackbar, 3750);
            }, () => {
                setSnackbar({
                    isOpen: true,
                    message: "Échec de l'envoi du message. Veuillez réessayer."
                });
                setTimeout(closeSnackbar, 3750);
            });
    };

    const closeSnackbar = () => {
        setSnackbar({
            ...snackbar,
            isOpen: false
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit} id="bexcon-simple-form" className="bexcon-simple-form">
                <input type="hidden" name="section" value="bexcon_form"/>
                <input type="hidden" name="reCAPTCHA"/>
                <div className="row form-group-margin">
                    <div className="col-12 col-md-6 m-0 p-2 input-group">
                        <input type="text" name="name" className="form-control field-name" placeholder="Nom" onChange={handleChange} value={formData.name}/>
                    </div>
                    <div className="col-12 col-md-6 m-0 p-2 input-group">
                        <input type="email" name="email" className="form-control field-email" placeholder="Email" onChange={handleChange} value={formData.email}/>
                    </div>
                    <div className="col-12 col-md-6 m-0 p-2 input-group">
                        <input type="text" name="phone" className="form-control field-phone" placeholder="Téléphone" onChange={handleChange} value={formData.phone}/>
                    </div>
                    <div className="col-12 col-md-6 m-0 p-2 input-group">
                        <i className="icon-arrow-down mr-3"></i>
                        <select name="info" className="form-control field-info" onChange={handleChange} value={formData.info}>
                            <option value="" disabled>Plus d'infos</option>
                            <option>Gestion de projets complets</option>
                            <option>Élaboration et suivi budgétaire</option>
                            <option>Consultation et expertise technique</option>
                            <option>Formation et supervision du personnel</option>
                            <option>Rédaction et gestion des documents contractuels</option>
                            <option>Planification et suivi des échéanciers</option>
                            <option>Autre</option>
                        </select>
                    </div>
                    <div className="col-12 m-0 p-2 input-group">
                        <textarea name="message" className="form-control field-message" placeholder="Message" onChange={handleChange} value={formData.message}></textarea>
                    </div>
                    <div className="col-12 col-12 m-0 p-2 input-group">
                        <span className="form-alert"></span>
                    </div>
                    <div className="col-12 input-group m-0 p-2">
                        <button type="submit" className="btn primary-button" disabled={!formData.name || !formData.email || !formData.message}>ENVOYER</button>
                    </div>
                </div>
            </form>
            <Snackbar
                isOpen={snackbar.isOpen}
                message={snackbar.message}
                onClose={closeSnackbar}
            />
        </>
    );
};

export default BexconForm;
