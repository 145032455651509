import React, {useEffect} from 'react';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/slider.min.css';
import './assets/css/main.css';
import './assets/css/vendor/icons.min.css';
import './assets/css/vendor/icons-fa.min.css';
import './assets/css/vendor/animation.min.css';
import './assets/css/vendor/gallery.min.css';
import './assets/css/default.css';
import loadScript from "./hooks/useScript";
import BexconForm from "./form/form";

function App() {
  useEffect(() => {
    const loadScripts = async () => {
      try {
        await loadScript('assets/js/vendor/jquery.min.js', 'jquery-min');
        const scripts = [
          'assets/js/vendor/jquery.easing.min.js',
          'assets/js/vendor/jquery.inview.min.js',
          'assets/js/vendor/bootstrap.min.js',
          'assets/js/vendor/slider.min.js',
          'assets/js/vendor/animation.min.js',
          'assets/js/main.js',
        ];

        for (const script of scripts) {
          await loadScript(script, script);
        }
      } catch (e) {}
    };

    loadScripts();
  }, []);
  return (
      <div className="home">
        <header id="header">
          <nav className="navbar navbar-expand top">
            <div className="container header">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href="#" className="nav-link pl-0">
                    <i className="fas fa-clock mr-2"></i>
                    Heures d'ouverture : Lun - Ven - 8:00 - 17:00
                  </a>
                </li>
              </ul>
              <div className="ml-auto"></div>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="fas fa-phone-alt mr-2"></i>
                    +1 (438) 928-0869
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="fas fa-envelope mr-2"></i>
                    rplourde.bexcon@hotmail.com
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav icons">
                <li className="nav-item social">
                  <a href="#" className="nav-link">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="nav-item social">
                  <a href="#" className="nav-link pr-0">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <nav className="navbar navbar-expand navbar-fixed sub">
            <div className="container header">
              <a className="navbar-brand light" href="/">
                            <span className="brand">
                                <span className="featured">
                                    <span className="first pr-2">BEX</span>
                                </span>
                                <span className="last">CON</span>
                            </span>
              </a>
              <div className="ml-auto"></div>
              <ul className="navbar-nav items">
                <li className="nav-item">
                  <a href="#header" className="smooth-anchor secondary-button">ACCUEIL</a>
                </li>
                <li className="nav-item">
                  <a href="#about" className="smooth-anchor secondary-button">À PROPOS</a>
                </li>
                <li className="nav-item">
                  <a href="#services" className="smooth-anchor secondary-button">SERVICES</a>
                </li>
                <li className="nav-item">
                  <a href="#projects" className="smooth-anchor secondary-button">RÉALISATIONS</a>
                </li>
                <li className="nav-item">
                  <a href="#process" className="smooth-anchor secondary-button">PROCESSUS</a>
                </li>
                <li className="nav-item">
                  <a href="#team" className="smooth-anchor secondary-button">ÉQUIPE</a>
                </li>
              </ul>
              <ul className="navbar-nav toggle">
                <li className="nav-item">
                  <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                    <i className="icon-menu m-0"></i>
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav action">
                <li className="nav-item ml-3">
                  <a href="#contact" className="smooth-anchor btn ml-lg-auto primary-button">CONTACTEZ-NOUS</a>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <section id="slider" className="hero p-0">
          <div className="swiper-container no-slider animation slider-h-100 slider-h-auto">
            <div className="swiper-wrapper">
              <div className="swiper-slide slide-center">
                <div className="parallax-y-bg" style={{backgroundImage: 'url(assets/images/jumbo.png)'}}></div>
                <div className="slide-content row">
                  <div className="col-12 d-flex justify-content-start inner">
                    <div className="left text-left">
                      <h1 data-aos="zoom-in" data-aos-delay="2000" className="title effect-static-text">
                        <span className="pre-title m-0">Construction & Immobilier</span>
                        Expert bâtisseur
                      </h1>
                      <div data-aos="fade-up" data-aos-delay="2800" className="buttons">
                        <div className="d-sm-inline-flex">
                          <a href="#contact" className="smooth-anchor mt-4 btn primary-button">CONTACTEZ-NOUS</a>
                          <a href="#about" className="smooth-anchor ml-sm-4 mt-4 btn outline-button">EN SAVOIR PLUS</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="section-1 odd highlights image-right">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 pr-md-5 align-self-center text-center text-md-left text">
                <div data-aos="fade-up" className="row intro">
                  <div className="col-12 p-0">
                    <span className="pre-title m-auto m-md-0">À propos de nous</span>
                    <h2><span className="featured"><span>Notre</span></span> Compagnie</h2>
                    <p>Chez Bexcon, nous nous engageons à offrir des solutions de gestion de projets de construction
                      innovantes et fiables. Avec plus de 25 ans d’expérience, notre équipe met tout en œuvre pour
                      assurer la réussite de chaque projet, en mettant l’accent sur la sécurité, la durabilité et
                      l’excellence.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 pr-md-5 align-self-center text-center text-md-left text">
                <div className="row items">
                  <div data-aos="fade-up" className="col-12 col-md-6 p-0 pr-md-4 item">
                    <h4><i className="mr-2 icon-badge"></i>Tradition</h4>
                    <p>Nous allions méthodes éprouvées et innovations pour des solutions optimales.</p>
                  </div>
                  <div data-aos="fade-up" className="col-12 col-md-6 p-0 pr-md-4 item">
                    <h4><i className="mr-2 icon-lock"></i>Sécurité</h4>
                    <p>La sécurité est notre priorité, avec des normes strictes pour tous nos chantiers.</p>
                  </div>
                </div>
                <div className="row items">
                  <div data-aos="fade-up" className="col-12 col-md-6 p-0 pr-md-4 item">
                    <h4><i className="mr-2 icon-note"></i>Certificat</h4>
                    <p>Nos certifications reflètent notre engagement envers la qualité et l'excellence.</p>
                  </div>
                  <div data-aos="fade-up" className="col-12 col-md-6 p-0 pr-md-4 item">
                    <h4><i className="mr-2 icon-graduation"></i>Expertise</h4>
                    <p>Notre équipe offre des solutions efficaces, adaptées à chaque défi.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="section-2 offers">
          <div className="container">
            <div className="row intro">
              <div className="col-12 col-md-9 align-self-center text-center text-md-left">
                <span className="pre-title m-auto ml-md-0">Nos domaines d'activité</span>
                <h2>Excellence dans les <span className="featured"><span>services conseils</span></span></h2>
                <p>Nous sommes leaders dans la fourniture de services de conseil avec un ensemble de technologies de
                  pointe et une équipe de professionnels expérimentés et renommés. Voici quelques options que vous
                  pouvez choisir.</p>
              </div>
            </div>
            <div className="row justify-content-center items">
              <div className="col-12 col-md-6 col-lg-4 item">
                <div className="card">
                  <div className="card-content">
                    <i className="icon icon-organization"></i>
                    <h4>Gestion de projets complets</h4>
                    <ul>
                      <li>Gestion de projets résidentiels, commerciaux et institutionnels</li>
                      <li>Supervision et gestion de travaux d’envergure</li>
                      <li>Suivi de projet jusqu’à sa clôture</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 item">
                <div className="card">
                  <div className="card-content">
                    <i className="icon icon-briefcase"></i>
                    <h4>Élaboration et suivi budgétaire</h4>
                    <ul>
                      <li>Élaboration et suivi budgétaire en cours de projet</li>
                      <li>Estimation et présentation des coûts supplémentaires pour fin de réclamation</li>
                      <li>Évaluation budgétaire pour fin de construction auprès des prêteurs hypothécaires</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 item">
                <div className="card">
                  <div className="card-content">
                    <i className="icon icon-chart"></i>
                    <h4>Consultation et expertise technique</h4>
                    <ul>
                      <li>Résolution de problématiques et analyse des plans et devis pour commentaires</li>
                      <li>Élaboration de techniques et méthodes de construction pour divers projets complexes</li>
                      <li>Analyse et commentaires des plans préliminaires pour fin de réduction des coûts</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 item">
                <div className="card">
                  <div className="card-content">
                    <i className="icon icon-plane"></i>
                    <h4>Formation et supervision du personnel</h4>
                    <ul>
                      <li>Formation de personnel en supervision et gestion de projets</li>
                      <li>Visite de chantiers pour suivi qualité et rédaction des listes de déficiences par discipline
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 item">
                <div className="card">
                  <div className="card-content">
                    <i className="icon icon-globe-alt"></i>
                    <h4>Rédaction et gestion des documents contractuels</h4>
                    <ul>
                      <li>Rédaction des appels d’offres et des contrats interdisciplinaires</li>
                      <li>Rédaction des conditions générales et des conditions complémentaires interdisciplinaires</li>
                      <li>Rédaction et gestion des documents contractuels</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 item">
                <div className="card">
                  <div className="card-content">
                    <i className="icon icon-drawer"></i>
                    <h4>Planification et suivi des échéanciers</h4>
                    <ul>
                      <li>Élaboration et suivi des échéanciers</li>
                      <li>Élaboration des plans d’actions temporaires pour la mise en chantier de projets
                        d’agrandissement en avant-projet et en cours de projet
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="projects" className="section-1 odd showcase blog-grid filter-section projects">
          <div className="overflow-holder">
            <div className="container">
              <div className="row text-center intro">
                <div className="col-12">
                  <span className="pre-title">nous construisons plus pour tout le monde</span>
                  <h2 className="mb-0">Nos<span className="featured"><span>réalisations</span></span></h2>
                </div>
              </div>
              <div className="row items filter-items">
                <div className="col-12 col-md-6 col-lg-4 item filter-item">
                  <div className="row card p-0 text-center">
                    <div className="image-over">
                      <img src="/assets/images/concordia.png" alt="Demospec"/>
                    </div>
                    <div className="card-caption col-12 p-0">
                      <div className="card-body">
                        <a href="#">
                          <h4>Demospec</h4>
                          <p>Nous avons géré un contrat de 6,945,000 $ pour la démolition et la décontamination des
                            salles mécaniques du pavillon H à l'Université Concordia.</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 item filter-item">
                  <div className="row card p-0 text-center">
                    <div className="image-over">
                      <img src="/assets/images/comingSoon.png" alt="coming soon"/>
                    </div>
                    <div className="card-caption col-12 p-0">
                      <div className="card-body">
                        <a href="#">
                          <h4>À venir</h4>
                          <p>Nous tenons à vous informer que d'autres projets passionnants sont en cours de
                            développement et seront bientôt dévoilés. Merci de votre confiance!</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 item filter-item">
                  <div className="row card p-0 text-center">
                    <div className="image-over">
                      <img src="/assets/images/comingSoon.png" alt="coming soon"/>
                    </div>
                    <div className="card-caption col-12 p-0">
                      <div className="card-body">
                        <a href="#">
                          <h4>À venir</h4>
                          <p>Nous tenons à vous informer que d'autres projets passionnants sont en cours de
                            développement et seront bientôt dévoilés. Merci de votre confiance!</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-1 filter-sizer"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="process" className="section-3 process offers">
          <div className="container full">
            <div className="row text-center intro">
              <div className="col-12">
                <span className="pre-title">Comment cela fonctionne en pratique</span>
                <h2><span className="featured"><span>Gestion</span></span> des processus</h2>
                <p className="text-max-800">Nous travaillons avec des méthodologies innovantes pour garantir que tout le
                  processus soit réalisé de bout en bout comme prévu.</p>
              </div>
            </div>
            <div className="row justify-content-center text-center items">
              <div className="col-12 col-md-6 col-lg-2 item">
                <div className="step"><span>01</span></div>
                <h4>Collecte d'informations</h4>
                <p>Recueillir toutes les informations nécessaires pour démarrer le projet.</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2 item">
                <div className="step"><span>02</span></div>
                <h4>Planification Stratégique</h4>
                <p>Élaborer un plan stratégique pour guider le projet de bout en bout.</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2 item">
                <div className="step"><span>03</span></div>
                <h4>Assignation des Responsabilités</h4>
                <p>Définir et assigner les responsabilités pour assurer une gestion efficace du projet.</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2 item">
                <div className="step"><span>04</span></div>
                <h4>Supervision et Suivi</h4>
                <p>Surveiller et contrôler l'avancement du projet pour assurer qu'il respecte les objectifs fixés.</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2 item">
                <div className="step"><span>05</span></div>
                <h4>Gestion Budgétaire et Contractuelle</h4>
                <p>Assurer la gestion financière et contractuelle du projet pour garantir sa viabilité économique et
                  juridique.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="team" className="section-4 odd highlights team image-right">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 align-self-top text-center text-md-left text">
                <div className="row intro">
                  <div className="col-12 p-0">
                    <span className="pre-title m-auto m-md-0">Nous aimons ce que nous construisons</span>
                    <h2><span className="featured"><span>Équipe</span></span> d'experts</h2>
                    <p>L'éthique et l'intégrité sont les bases de la carrière de nos professionnels.<br/>Ce sont des
                      attitudes quotidiennes.</p>
                  </div>
                </div>
                <div className="row items text-left">
                  <div className="col-12 col-md-6 p-0">
                    <div className="row item">
                      <div className="col-4 p-0 pr-3 align-self-center">
                        <img src="/assets/images/ctech.png" alt="Person" className="person"/>
                      </div>
                      <div className="col-8 align-self-center text-left">
                        <h4>Dominic Parent</h4>
                        <p>CHARGÉ DE PROJETS</p>
                        <ul className="navbar-nav social share-list ml-auto">
                          <li className="nav-item">
                            <a href="https://www.facebook.com/dominic.parent.5/" className="nav-link"><i
                                className="fab fa-facebook-f"></i></a>
                          </li>
                          <li className="nav-item">
                            <a href="https://www.linkedin.com/in/dominic-parent-24a660251/" className="nav-link"><i
                                className="fab fa-linkedin-in"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row item">
                      <div className="col-4 p-0 pr-3 align-self-center">
                        <img src="/assets/images/cm.png" alt="Person" className="person"/>
                      </div>
                      <div className="col-8 align-self-center text-left">
                        <h4>Gabriel R.-P.</h4>
                        <p>DIRECTEUR WEB & MARKETING</p>
                        <ul className="navbar-nav social share-list ml-auto">
                          <li className="nav-item">
                            <a href="https://www.facebook.com/Gab.Ray29/" className="nav-link"><i
                                className="fab fa-facebook-f"></i></a>
                          </li>
                          <li className="nav-item">
                            <a href="https://www.linkedin.com/in/gabrpg/" className="nav-link"><i
                                className="fab fa-linkedin-in"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 p-0">
                    <div className="row item">
                      <div className="col-4 p-0 pr-3 align-self-center">
                        <img src="/assets/images/ceo.png" alt="Person" className="person"/>
                      </div>
                      <div className="col-8 align-self-center text-left">
                        <h4>Robert Plourde</h4>
                        <p>PDG & PRÉSIDENT</p>
                        <ul className="navbar-nav social share-list ml-auto">
                          <li className="nav-item">
                            <a href="https://www.facebook.com/profile.php?id=100067565690571/" className="nav-link"><i
                                className="fab fa-facebook-f"></i></a>
                          </li>
                          <li className="nav-item">
                            <a href="https://www.linkedin.com/in/robert-plourde-4654a9298/" className="nav-link"><i
                                className="fab fa-linkedin-in"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row item">
                      <div className="col-4 p-0 pr-3 align-self-center">
                        <img src="/assets/images/vp.png" alt="Person" className="person"/>
                      </div>
                      <div className="col-8 align-self-center text-left">
                        <h4>Chantal Rondeau</h4>
                        <p>VICE-PRÉSIDENTE</p>
                        <ul className="navbar-nav social share-list ml-auto">
                          <li className="nav-item">
                            <a href="#" className="nav-link"><i
                                className="fab fa-facebook-f"></i></a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link"><i
                                className="fab fa-linkedin-in"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos="zoom-in" className="col-12 col-lg-4 align-self-end">
                <div className="quote">
                  <div className="quote-content">
                    <h4>Discours du Président</h4>
                    <p>Notre objectif est de réaliser des projets qui dépassent les attentes de nos clients, en
                      priorisant la sécurité, la durabilité et l'innovation.</p>
                    <p>À l'avenir, nous sommes prêts à relever les défis avec la confiance de nos clients et
                      partenaires. Nous continuerons d'investir dans la technologie et le développement de nos talents
                      pour renforcer nos partenariats.</p>
                    <p>Merci pour votre confiance et votre soutien. Ensemble, construisons l'avenir en tant qu'experts
                      bâtisseurs.</p>
                    <h5>Robert Plourde</h5>
                  </div>
                  <i className="quote-right fas fa-quote-right"></i>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="section-5 form contact">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 pr-md-5 align-self-center text">
                <div className="row intro">
                  <div className="col-12 p-0">
                    <span className="pre-title m-0">envoyer un message</span>
                    <h2>Contactez-<span className="featured"><span>nous</span></span></h2>
                    <p>Nous répondrons à votre message dès que possible.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 p-0">
                    <BexconForm/>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="contacts">
                  <h4>Bexcon Inc.</h4>
                  <p>Developper des solutions innovantes afin d’optimiser le délai de livraison de vos projets.</p>
                  <p>Élaborer des processus de suivi de projet garantissant une qualité optimale tout en assurant le
                    dépassement des normes de sécurité et de construction en vigueur.</p>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="fas fa-phone-alt mr-2"></i>
                        +1 (438) 928-0869
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="stepnav-link">
                        <i className="fas fa-envelope mr-2"></i>
                        rplourde.bexcon@hotmail.com
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="https://www.google.ca/maps/place/45%C2%B053'29.3%22N+73%C2%B016'14.1%22W/@45.8914737,-73.2731519,17z/"
                         className="nav-link">
                        <i className="fas fa-map-marker-alt mr-2"></i>
                        26 Terrasse de la Colombe, Lavaltrie, QC, J5T 1B1
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="mt-2 btn outline-button" data-toggle="modal" data-target="#map">VOIR LA
                        CARTE</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <section id="copyright" className="p-3 odd copyright">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 p-3 text-center text-lg-left">
                  <p>Bexcon - Expert bâtisseur</p>
                </div>
                <div className="col-12 col-md-6 p-3 text-center text-lg-right">
                  <p>© 2024 Bexcon is powered by <a href="https://vercel.com/">Vercel</a>.</p>
                </div>
              </div>
            </div>
          </section>
        </footer>

        <div id="map" className="p-0 modal fade" role="dialog" aria-labelledby="map" aria-hidden="true">
          <div className="modal-dialog modal-dialog-slideout" role="document">
            <div className="modal-content full">
              <div className="modal-header absolute" data-dismiss="modal">
                <i className="icon-close fas fa-arrow-right"></i>
              </div>
              <div className="modal-body p-0">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2810.51844594213!2d-73.29998248446452!3d45.942312579108226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc8a04f4ed7d51b%3A0xd3a20ae4f0dced16!2s26%20Terrasse%20de%20la%20Colombe%2C%20Lavaltrie%2C%20QC%20J5T%201B1%2C%20Canada!5e0!3m2!1sen!2sus!4v1622549762454!5m2!1sen!2sus&markers=color:red%7C45.942312579108226,-73.29998248446452"
                    width="600"
                    height="450"
                    style={{border: 0}}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div id="menu" className="p-0 modal fade" role="dialog" aria-labelledby="menu" aria-hidden="true">
          <div className="modal-dialog modal-dialog-slideout" role="document">
            <div className="modal-content full">
              <div className="modal-header" data-dismiss="modal">
                <i className="icon-close fas fa-arrow-right"></i>
              </div>
              <div className="menu modal-body">
                <div className="row w-100">
                  <div className="items p-0 col-12 text-center"></div>
                  <div className="contacts p-0 col-12 text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="scroll-to-top" className="scroll-to-top">
          <a href="#header" className="smooth-anchor">
            <i className="fas fa-arrow-up"></i>
          </a>
        </div>
      </div>
  );
}

export default App;
