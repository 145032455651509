import React from 'react';
import './snackbar.css';

const Snackbar = ({ message, isOpen, onClose }) => {
    return (
        <div className={`snackbar ${isOpen ? 'show' : ''}`}>
            {message}
            <button className="close-btn" onClick={onClose}>X</button>
        </div>
    );
};

export default Snackbar;
